export default [
    {
        title: 'Sidebar.Dashboard',
        route: 'dashboard',
        icon: 'home',
    },
    {
        title: 'Sidebar.Settings',
        route: 'settings',
        icon: 'recycle',
    },
    {
        title: 'Sidebar.Subscription',
        route: 'subscription',
        icon: 'view-dashboard',
    },
    {
        title: 'Sidebar.ErrorLog',
        route: 'errorLog',
        icon: 'alert',
    },
    {   
        title: 'Sidebar.BillingPage',
        route: 'billing',
        icon: 'wallet',
    },
    {
        title: 'Sidebar.Faq',
        route: 'faq',
        icon: 'help-circle',
    },
];
