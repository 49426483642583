<template>
    <b-nav-item-dropdown
        id="dropdown-grouped"
        variant="link"
        class="dropdown-language"
        right
    >
        <template #button-content>
            <span class="ml-50 text-body">{{ currentLocale.name }}</span>
        </template>

        <b-dropdown-item
            v-for="localeObj in locales"
            :key="localeObj.locale"
            @click="changeLocale(localeObj.locale)"
        >
            <span class="ml-50">{{ localeObj.name }}</span>
        </b-dropdown-item>
    </b-nav-item-dropdown>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig';
export default {
    // ! Need to move this computed property to comp function once we get to Vue 3

    methods: {
        changeLocale(value) {
            const {lang} = useAppConfig();
            this.$i18n.locale = value;
            lang.value = value;

            this.locale = value;

            localStorage.setItem('lang', value);
        },
    },
    computed: {
        currentLocale() {
            return this.locales.find(l => l.locale === this.$i18n.locale);
        },
    },
    setup() {
        /* eslint-disable global-require */
        const locales = [
            {
                locale: 'en',
                name: 'English',
            },
            {
                locale: 'sv',
                name: 'Swedish',
            },
        ];
        /* eslint-disable global-require */

        return {
            locales,
        };
    },
};
</script>

<style></style>
