<template>
    <ul>
        <li class="nav-item ml-2">
            <p class="font-weight-bold mb-0">{{ t('Sidebar.Menu') }}</p>
        </li>
        <component
            :is="resolveNavItemComponent(item)"
            v-for="item in items"
            :key="item.header || item.title"
            :item="item"
        />
    </ul>
</template>

<script>
import {resolveVerticalNavMenuItemComponent as resolveNavItemComponent} from '@core/layouts/utils';
import {provide, ref} from '@vue/composition-api';
import VerticalNavMenuHeader from '../vertical-nav-menu-header';
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue';
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue';
import {useUtils as useI18nUtils} from '@core/libs/i18n';

export default {
    components: {
        VerticalNavMenuHeader,
        VerticalNavMenuLink,
        VerticalNavMenuGroup,
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
    },
    setup() {
        const {t} = useI18nUtils();
        provide('openGroups', ref([]));

        return {
            t,
            resolveNavItemComponent,
        };
    },
};
</script>
